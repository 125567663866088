import { FunctionComponent, ReactElement } from 'react';
import LoadingSpinner from '@/components/ui/loading-spinner';

interface OutputLoaderProps {
	completed?: boolean;
}

const OutputLoader: FunctionComponent<OutputLoaderProps> = ({ completed }): ReactElement => (
	<div className="m-auto flex max-w-[872px] flex-col gap-6 px-6 py-[96px]">
		<LoadingSpinner completed={completed} />
		<p className="text-intro-script">Analyzing...</p>
	</div>
);

export default OutputLoader;
