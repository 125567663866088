import { SlowBrainTileSelectionStep, StepDescriptionDialogContent } from '../../../types';

export const STEP_DESCRIPTION_DIALOG_CONTENT_MAP: Record<
	SlowBrainTileSelectionStep,
	StepDescriptionDialogContent
> = {
	personal_outcomes: {
		serialNumber: 1,
		title: 'Personal Outcomes',
		subtitles: [
			'Let’s start the Slow Brain Thinking exercise with Personal Outcomes.  Read the question below and click “I’m Ready” when you have considered your answer.',
		],
		info: [
			'"Think about your life much further into the future. This could be many years or even decades from now. What outcomes about your personal life do you want to be true?"',
		],
		actionCopy: 'I’m Ready',
	},
	money_outcomes: {
		serialNumber: 2,
		title: 'Money Outcomes',
		subtitles: [
			'The second part of the "Slow Brain" section is your Money Outcomes. To arrive at these outcomes, read the question below and click “I’m ready” when you have considered your answer.',
		],
		info: ['"What do you want your money to do for you?"'],
		actionCopy: 'I’m Ready',
	},
	plan_vulnerabilities: {
		serialNumber: 3,
		title: 'Plan Vulnerabilities',
		subtitles: [
			'The final section of "Slow Brain" is your Plan Vulnerabilities. To discover these, read the question below and click “I’m ready” when you have considered your answer.',
		],
		info: ['"Where do you feel a lack of plan or control in your life?"'],
		actionCopy: 'I’m Ready',
	},
	top_5_selection: {
		serialNumber: 4,
		title: 'Top 5 Selection',
		subtitles: [
			'Using all the items you’ve selected so far; we are going to ask you to prioritize them next. On the next screen, choose the 5 top tiles that resonate the most for you and rank them in order of importance. It does not matter how many of each color or type you choose.',
			'Click on the tiles to select or deselect your top 5.',
		],
		info: [],
		actionCopy: 'Begin Prioritizing',
	},
};
