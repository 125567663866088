import { SlowBrainHint } from '@/shared/types/hint.types';

export const slowBrainHints: SlowBrainHint = {
	tileSelection: {
		personal_outcomes: [
			{
				title: '',
				data: [
					`For the time being do not edit or limit your number of tile choices, we will get to that later.`,
					`With all the tile selections, think of how they would provide you with the ideal life you choose to live.`,
					`Remember put yourself into the future and looking back on your life select the tiles that if you didn’t have these in your life your life you would have not lived the way you want to live.`,
				],
			},
		],
		money_outcomes: [
			{
				title: '',
				data: [
					`For the time being do not edit or limit your number of tile choices, we will get to that later.`,
					`This is what you want your money to do for you to provide you with your ideal life.`,
					`If my money did not do these things I did not live my ideal life`,
				],
			},
		],
		plan_vulnerabilities: [
			{
				title: '',
				data: [
					`For the time being do not edit or limit your number of tile choices, we will get to that later.`,
					`Remember these are tiles that lead to a sense of uncertainty or lack of control`,
				],
			},
		],
		top_5_selection: [
			{
				title: '',
				data: [
					`You must choose 5 tiles`,
					`Do not feel the need to represent every color`,
					`Pick as many or as few of each color and arriving at 5 total`,
				],
			},
		],
	},
};
