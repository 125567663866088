import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const SlowBrainTileSelectionIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Selection"
		heading="Slow Brain"
		link={{ to: '../slow-brain/tile-selection', text: 'Start Tile Selection' }}
	>
		<div className="flex flex-col gap-6">
			<p>
				What will follow are three sections of tiles that will represent your ideal life. Unlike the
				first section, which was fast brain, take your time selecting these tiles and choose as many
				as seem relevant to living your ideal life. We focus on three critical aspects of
				self-discovery: Personal Outcomes, Money Outcomes, Plan Vulnerabilities.
			</p>
		</div>
	</IntroLayout>
);

export default SlowBrainTileSelectionIntro;
