import { RiCloseLargeLine, RiInformationLine } from '@remixicon/react';
import { FunctionComponent } from 'react';

interface PopupHeaderProps {
	title?: string;
	close?: () => void;
}

const HintsPopupHeader: FunctionComponent<PopupHeaderProps> = ({
	title = 'Helpful Hints',
	close,
}) => (
	<div className="flex items-center justify-between pr-4">
		<div className="flex items-center text-body-16-md font-bold text-blue-dark">
			<RiInformationLine size={16} color="#34ABE3" className="mr-2" />
			{title}
		</div>
		<button
			onClick={close}
			className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-50"
		>
			<RiCloseLargeLine size={14} color="#000000" />
		</button>
	</div>
);

export default HintsPopupHeader;
