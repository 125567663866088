import { FormEvent, Fragment, FunctionComponent, ReactElement } from 'react';
import { PortfolioConsiderationQuestion, Question } from '@/api/questions/types';
import { QuestionAnswer } from '@/shared/types/questions.types';
import { ButtonNext } from '@/components/ui/button';
import { YesNoAnswer } from '@/components/ui/inputs';

interface PortfolioRecommendationsFormProps {
	questionsGroup: PortfolioConsiderationQuestion[];
	answers: QuestionAnswer;
	onAnswerChange: (questionId: Question['id'], answer: string) => void;
	onSubmit: () => void;
	disabled: boolean;
}

const PortfolioRecommendationsForm: FunctionComponent<PortfolioRecommendationsFormProps> = ({
	questionsGroup,
	answers,
	onAnswerChange,
	onSubmit,
	disabled,
}): ReactElement => {
	const onFormSubmit = (event: FormEvent) => {
		event.preventDefault();
		onSubmit();
	};

	return (
		<form onSubmit={onFormSubmit}>
			<div className="flex flex-col">
				{questionsGroup.map(({ title, questions }, idx) => (
					<div key={idx}>
						<p className="mb-5 mt-4 text-body-18 text-blue-dark">{title}</p>
						{questions.map(
							({ id: questionId, questionMetadata, choices, orderNo, disabledNo, disabledYes }) => (
								<Fragment key={questionId}>
									<div className="flex flex-row items-center justify-between gap-6">
										<p className="flex-1">
											{orderNo}. {questionMetadata[0].text}
										</p>
										<YesNoAnswer
											id={questionId}
											name={questionId}
											value={answers[questionId]?.[0]}
											yesValue={choices[0].id}
											noValue={choices[1].id}
											disabledNo={disabledNo}
											disabledYes={disabledYes}
											onChange={(answer: string) => onAnswerChange(questionId, answer)}
										/>
									</div>
									<div className="my-4 h-[1px] w-full bg-neutral-200 opacity-30" />
								</Fragment>
							)
						)}
					</div>
				))}
			</div>
			<div className="mt-12 flex justify-end">
				<ButtonNext type="submit" disabled={disabled}>
					Continue
				</ButtonNext>
			</div>
		</form>
	);
};

export default PortfolioRecommendationsForm;
