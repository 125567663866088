import {
	ConsiderationQuestionGroups,
	PortfolioConsiderationQuestion,
	QuestionType,
} from '../types';

export const portfolioRecommendationQuestions: PortfolioConsiderationQuestion[] = [
	{
		title: 'Liquidity Needs',
		groupId: ConsiderationQuestionGroups.LIQUIDITY_NEEDS,
		questions: [
			{
				flow: 'recommendation',
				category: '',
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: 'b3cdd797-e456-4f61-b0df-406200cbba41',
						text: 'Yes',
					},
					{
						id: '56879634-1bfe-4edb-b65c-6370c4b0013f',
						text: 'No',
					},
				],
				orderNo: 1,
				id: '7a2679c7-5a6f-4406-920b-290ce2ca6147',
				questionMetadata: [
					{
						label: '',
						text: 'You need your portfolio to supplement your income',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
			{
				flow: 'recommendation',
				category: null,
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: '8154f793-ddb7-4516-b9fc-66df4fefb230',
						text: 'YES',
					},
					{
						id: '8edb1fd7-a518-4087-b01f-2eeb707889c2',
						text: 'NO',
					},
				],
				orderNo: 2,
				id: '5f715920-ec46-13d4-1bc2-a417070ca796',
				questionMetadata: [
					{
						label: '',
						text: 'You intend to withdraw principal from your account',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
		],
	},
	{
		title: 'Portfolio Belief System',
		groupId: ConsiderationQuestionGroups.PORTFOLIO_BELIEF_SYSTEM,
		questions: [
			{
				flow: 'recommendation',
				category: null,
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: '1b1e9737-35eb-409b-8745-4937056c6999',
						text: 'YES',
					},
					{
						id: 'ddbd80dc-62fb-4298-9743-5995108ac505',
						text: 'NO',
					},
				],
				orderNo: 3,
				id: '745911b3-b965-42f0-913a-83dec7802539',
				questionMetadata: [
					{
						label: '',
						text: 'You believe it is impossible to time the markets and that by mirroring the market you will receive returns that are consistent with your assigned asset allocation and the overall market',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
			{
				flow: 'recommendation',
				category: '',
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: '8051d956-0b64-47fa-b32d-788dd37e9391',
						text: 'Yes',
					},
					{
						id: 'ee993624-127d-480e-ba83-59197987af58',
						text: 'No',
					},
				],
				orderNo: 4,
				id: '39b02c8b-c3af-4fa1-a004-f71b3166bd10',
				questionMetadata: [
					{
						label: '',
						text: 'You believe that manager selection and active portfolio management can produce superior returns over time',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
			{
				flow: 'recommendation',
				category: null,
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: '44132334-c5fa-47df-b145-a262cd4da2cf',
						text: 'YES',
					},
					{
						id: 'fd6a3d21-5c5f-45ba-8bed-b714e816334c',
						text: 'NO',
					},
				],
				orderNo: 5,
				id: '5240bde0-1920-a52a-5a70-1f52ff7a7ff3',
				questionMetadata: [
					{
						label: '',
						text: 'You believe that owning individual equities (stocks) can provide similar diversification to a mutual fund/exchange traded fund but provide additional tax advantages while maintaining the potential for market returns?',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
		],
	},
	{
		title: 'Retirement Horizon',
		groupId: ConsiderationQuestionGroups.RETIREMENT_HORIZON,
		questions: [
			{
				flow: 'recommendation',
				category: '',
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: 'c7f9c863-ea16-4e5d-9229-a443138e7907',
						text: 'Yes',
					},
					{
						id: '018a99d2-f516-4a4b-9b49-6fc0d4bc53a1',
						text: 'No',
					},
				],
				orderNo: 6,
				id: '7c94f630-acc2-493b-91e5-f942da96c1b9',
				questionMetadata: [
					{
						label: '',
						text: 'You are within 5 years of retirement',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
			{
				flow: 'recommendation',
				category: '',
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: 'd9433ac0-e349-486c-82f2-317de74a67ba',
						text: 'Yes',
					},
					{
						id: 'bea8184b-21d4-4aee-b451-0eb9b174ac99',
						text: 'No',
					},
				],
				orderNo: 7,
				id: '905033e9-b5a5-433d-a537-8981055900f3',
				questionMetadata: [
					{
						label: '',
						text: 'You have retired within the last five years',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
		],
	},
	{
		title: 'Portfolio Themes',
		groupId: ConsiderationQuestionGroups.PORTFOLIO_THEMES,
		questions: [
			{
				flow: 'recommendation',
				category: '',
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: 'f2c85937-79f0-4f1f-b68a-63fa9eee68c6',
						text: 'Yes',
					},
					{
						id: '64238de6-1069-489e-b4a5-a346d241b79d',
						text: 'No',
					},
				],
				orderNo: 8,
				id: '8553abcf-a288-4228-998e-0e3c7d1c7950',
				questionMetadata: [
					{
						label: '',
						text: 'You would prefer your portfolio to maximize tax efficiency to reduce the taxable implication of your portfolio potentially at the expense of higher returns',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
			{
				flow: 'recommendation',
				category: null,
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: 'e4f3ed63-d15f-48fa-8669-decace50a970',
						text: 'YES',
					},
					{
						id: '9ed91fdb-a0db-4576-a19a-2a64f75b0a0d',
						text: 'NO',
					},
				],
				orderNo: 9,
				id: '233a6fab-d2e9-7418-26ed-1b228f6840b6',
				questionMetadata: [
					{
						label: '',
						text: 'You would like your portfolio to represent you socially conscious belief system',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
			{
				flow: 'recommendation',
				category: null,
				tile: null,
				tag: null,
				type: QuestionType.SINGLE_CHOICE,
				choices: [
					{
						id: 'ecc3a166-250b-48e8-949e-1b576a8cd1e6',
						text: 'YES',
					},
					{
						id: '823441b7-dbeb-4d37-9f00-dfd1e8c7f7ca',
						text: 'NO',
					},
				],
				orderNo: 10,
				id: '4b1166a2-149a-c374-bacf-6886e8f780ce',
				questionMetadata: [
					{
						label: '',
						text: 'You believe the use of Alternative Investments (i.e.: Private Equity, Private Debt, Real Estate, etc.) will reduce volatility and potentially smoothen out returns over longer periods of time, and are you willing to accept liquidity limitations on that portion of your portfolio?',
						subtext: '',
						hint: '',
						imageUrl: '',
						required: false,
					},
				],
			},
		],
	},
];
