import { FunctionComponent } from 'react';
import { HintList } from '@/shared/types/hint.types';
import { classNames } from '@/lib/utils';

interface PopupBodyProps {
	data: HintList[];
	description?: string;
}

const HintsPopupBodyList: FunctionComponent<PopupBodyProps> = ({ data, description }) => {
	const formatBoldText = (text: string): (string | JSX.Element)[] => {
		const parts: string[] = text.split(/(".*?")/g);
		return parts.map((part: string, index: number) =>
			part.startsWith('"') && part.endsWith('"') ? (
				<span key={index}>
					<b>&quot;{part.slice(1, -1)}&quot;</b>
				</span>
			) : (
				part
			)
		);
	};

	return (
		<div className="mt-4 max-h-[362px] overflow-y-auto">
			{description && <p className="mb-4 text-label-m">{description}</p>}
			{data.map((item, idx) => (
				<div className="space-y-4" key={idx}>
					{item.title && (
						<p className={classNames('font-semibold', description ? 'mt-4' : '')}>{item.title}</p>
					)}
					{item.description && <p className="mb-4 text-label-m">{item.description}</p>}
					{item.additionalData && (
						<ul className="mr-4 list-disc pl-5 text-label-m">
							{item.additionalData?.map((item: string, ind: number) => (
								<li key={ind}>{formatBoldText(item)}</li>
							))}
						</ul>
					)}
					{item.exampleTitle && <p className="mt-4 text-label-m">{item.exampleTitle}</p>}
					<ul className="mr-4 list-disc pl-5 text-label-m">
						{item.data.map((item: string, ind: number) => (
							<li key={ind}>{formatBoldText(item)}</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
};

export default HintsPopupBodyList;
