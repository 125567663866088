/* eslint-disable no-param-reassign */
import { signal, computed } from '@preact/signals-react';
import { AnimationGroup } from '@babylonjs/core';
import { InternalTileData, TileData, SlotData } from '@/views/scenes/types/tiles.types';
import { TileGroup, TileGroupSelected } from '@/api/tile-groups/types';

// fast brain tiles and slots state
export const fastBrainTilesData = signal<InternalTileData[]>([]);
export const fastBrainSlotsData = signal<SlotData[]>([]);

// Used to set "continue" button in the fastbrain flow to disabled/enabled
export const isFastBrainDisabled = computed(() =>
	fastBrainSlotsData.value.every((t) => !t.isOccupied)
);
// set and format selected tiles from fast brain flow
export const fastBrainSelectedTiles = computed(() => {
	const selectedTilesGroup: TileGroupSelected = {
		id: '',
		flow: 'fast_brain',
		category: '',
		tiles: [],
	};

	fastBrainSlotsData.value.forEach((slot) => {
		if (slot.isOccupied && slot.tile) {
			selectedTilesGroup.tiles.push({
				tag: slot.tile.tag,
				text: slot.tile.text,
				slot: {
					slotId: slot.id,
					targetTile: slot.tile.id,
				},
			});
		}
	});

	return [selectedTilesGroup];
});

// slow brain tiles state per category
export const slowBrainPersonalTilesData = signal<InternalTileData[]>([]);
export const slowBrainMoneyTilesData = signal<InternalTileData[]>([]);
export const slowBrainVulnerabilitiesTilesData = signal<InternalTileData[]>([]);
export const slowBrainTop5TilesData = signal<InternalTileData[]>([]);

// slow brain slots state per category
export const slowBrainPersonalSlotsData = signal<SlotData[]>([]);
export const slowBrainMoneySlotsData = signal<SlotData[]>([]);
export const slowBrainVulnerabilitiesSlotsData = signal<SlotData[]>([]);
export const slowBrainTop5SlotsData = signal<SlotData[]>([]);

export const isSlowBrainPersonalDisabled = computed(() =>
	slowBrainPersonalSlotsData.value.every((t) => !t.isOccupied)
);
export const isSlowBrainMoneyDisabled = computed(() =>
	slowBrainMoneySlotsData.value.every((t) => !t.isOccupied)
);
export const isSlowBrainVulnerabilitiesDisabled = computed(() =>
	slowBrainVulnerabilitiesSlotsData.value.every((t) => !t.isOccupied)
);
export const isSlowBrainTop5Disabled = computed(() =>
	slowBrainTop5SlotsData.value.some((t) => !t.isOccupied)
);

// slow brain top5, calculated from all previous slow brain categories state to define which tiles should exist in top5 available for selection
export const slowBrainTileGroupsTop5 = computed(() => {
	const tileGroups: TileGroup[] = [];

	const addTilesToGroup = (slotsData: SlotData[], category: string, orderNo: number) => {
		const occupiedTiles = slotsData
			.filter((slot) => slot.isOccupied && slot.tile !== null)
			.map((slot) => slot.tile!);

		if (occupiedTiles.length > 0) {
			tileGroups.push({
				id: '',
				tiles: occupiedTiles,
				category,
				flow: 'slow_brain',
				orderNo,
			});
		}
	};

	addTilesToGroup(slowBrainPersonalSlotsData.value, 'Personal', 1);
	addTilesToGroup(slowBrainMoneySlotsData.value, 'Money', 2);
	addTilesToGroup(slowBrainVulnerabilitiesSlotsData.value, 'Vulnerabilities', 3);

	return tileGroups;
});

// setup and format selected tiles from PersonalOutcomes
export const slowBrainPersonalSelectedTiles = computed(() => {
	const selectedTilesGroup: TileGroupSelected = {
		id: '',
		flow: 'slow_brain',
		category: 'Personal',
		tiles: [],
	};

	slowBrainPersonalSlotsData.value.forEach((slot) => {
		if (slot.isOccupied && slot.tile) {
			selectedTilesGroup.tiles.push({
				tag: slot.tile.tag,
				text: slot.tile.text,
				slot: {
					slotId: slot.id,
					targetTile: slot.tile.id,
				},
			});
		}
	});

	return [selectedTilesGroup];
});

// setup and format selected tiles from MoneyOutcomes
export const slowBrainMoneySelectedTiles = computed(() => {
	const selectedTilesGroup: TileGroupSelected = {
		id: '',
		flow: 'slow_brain',
		category: 'Money',
		tiles: [],
	};

	slowBrainMoneySlotsData.value.forEach((slot) => {
		if (slot.isOccupied && slot.tile) {
			selectedTilesGroup.tiles.push({
				tag: slot.tile.tag,
				text: slot.tile.text,
				slot: {
					slotId: slot.id,
					targetTile: slot.tile.id,
				},
			});
		}
	});

	return [selectedTilesGroup];
});

// setup and format selected tiles from PlanVulnerabilities
export const slowBrainVulnerabilitiesSelectedTiles = computed(() => {
	const selectedTilesGroup: TileGroupSelected = {
		id: '',
		flow: 'slow_brain',
		category: 'Vulnerabilities',
		tiles: [],
	};

	slowBrainVulnerabilitiesSlotsData.value.forEach((slot) => {
		if (slot.isOccupied && slot.tile) {
			selectedTilesGroup.tiles.push({
				tag: slot.tile.tag,
				text: slot.tile.text,
				slot: {
					slotId: slot.id,
					targetTile: slot.tile.id,
				},
			});
		}
	});

	return [selectedTilesGroup];
});

// setup and format selected tiles from Top5
export const slowBrainTop5SelectedTiles = computed(() => {
	const selectedTilesGroup: TileGroupSelected = {
		id: '',
		flow: 'slow_brain',
		category: 'Top5',
		tiles: [],
	};

	slowBrainTop5SlotsData.value.forEach((slot) => {
		if (slot.isOccupied && slot.tile) {
			selectedTilesGroup.tiles.push({
				tag: slot.tile.tag,
				text: slot.tile.text,
				slot: {
					slotId: slot.id,
					targetTile: slot.tile.id,
				},
			});
		}
	});

	return [selectedTilesGroup];
});

// update method to set if tile is selected or not
export function updateTileIsSelected(
	targetSignal: { value: InternalTileData[] },
	name: string,
	isSelected: boolean
) {
	const updatedTiles = targetSignal.value.map((tile) =>
		tile.name === name ? { ...tile, isSelected } : tile
	);

	targetSignal.value = updatedTiles;
}

// update method to set the stacking animation to tile upon animation creation
export const updateTileStackingAnimation = (
	targetSignal: { value: InternalTileData[] },
	tileName: string,
	newStackingAnimation: AnimationGroup | null
) => {
	const updatedTiles = targetSignal.value.map((tile) =>
		tile.name === tileName ? { ...tile, stackingAnimation: newStackingAnimation } : tile
	);

	targetSignal.value = updatedTiles;
};

// update method to set the hover animation to tile upon animation creation
export const updateTileHoverAnimation = (
	targetSignal: { value: InternalTileData[] },
	tileName: string,
	newHoverAnimation: AnimationGroup | null
) => {
	const updatedTiles = targetSignal.value.map((tile) =>
		tile.name === tileName ? { ...tile, hoverAnimation: newHoverAnimation } : tile
	);

	targetSignal.value = updatedTiles;
};
// update method for slots data to set that slot is occupied or not, and which tile it is occupied by
export const updateSlotData = (
	targetSignal: { value: SlotData[] },
	id: number,
	isOccupied: boolean,
	tile: TileData | null
) => {
	const updatedSlots = targetSignal.value.map((slot) =>
		slot.id === id ? { ...slot, isOccupied, tile } : slot
	);

	targetSignal.value = updatedSlots;
};

// flag which represents if BabylonLoader should be visible or not
export const isSceneLoading = signal<boolean>(true);
