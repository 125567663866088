import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const FastBrainTileQuestionsIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Reflection"
		heading="Fast Brain"
		link={{ to: '../fast-brain/tile-questions', text: 'Start Tile Reflection' }}
	>
		Now that you have selected your tiles, you will now be asked to reflect on specific moments or
		events in your life where one of these tiles was clearly represented.
	</IntroLayout>
);

export default FastBrainTileQuestionsIntro;
