import { FunctionComponent } from 'react';
import Modal, { ModalProps } from '@/components/ui/modal';
import { StepDescriptionDialogContent } from '../../../types';
import { ButtonNext } from '@/components/ui/button';

type StepDescriptionDialogProps = StepDescriptionDialogContent &
	Pick<ModalProps, 'onClose' | 'open'>;

const StepDescriptionDialog: FunctionComponent<StepDescriptionDialogProps> = ({
	serialNumber,
	title,
	subtitles,
	info,
	actionCopy,
	onClose,
	open,
}) => (
	<Modal open={open} onClose={onClose} keyName={`${serialNumber}-modal`}>
		<div className="w-[644px] p-12">
			<div className="flex flex-col gap-8">
				<div className="flex flex-col gap-6">
					<div className="flex flex-row items-center justify-center gap-2 text-heading-24 text-blue-dark">
						{!!serialNumber && (
							<div className="flex h-[36px] w-[36px] items-center justify-center rounded-full border-[3px] border-blue-primary text-heading-24">
								{serialNumber}
							</div>
						)}
						{title}
					</div>
					{subtitles.map((el, i) => (
						<div
							className="rounded-xl text-center text-body-16-md text-body"
							key={`${serialNumber}${i}`}
						>
							{el}
						</div>
					))}
					{!!info.length && (
						<div className="rounded-2xl bg-blue-50 p-4 text-center text-heading-24 text-body">
							{info.map((el, i) => (
								<span key={`${serialNumber}${i}`}>
									{el} <br />
								</span>
							))}
						</div>
					)}
				</div>
				<div className="text-center">
					<ButtonNext onClick={onClose}>{actionCopy}</ButtonNext>
				</div>
			</div>
		</div>
	</Modal>
);

export default StepDescriptionDialog;
