import { httpClient } from '@/api/http';
import { AnswerPayload } from './types';
import { Flow } from '../users/types';

export const postAnswers = async (payload: Array<AnswerPayload>, flow?: Flow): Promise<void> => {
	const route = flow ? `/answers?flow=${flow}` : '/answers';
	const { data } = await httpClient.post<void>(route, payload);

	return data;
};
