import {
	FunctionComponent,
	MutableRefObject,
	ReactElement,
	ReactNode,
	useEffect,
	useRef,
} from 'react';
import { Question, QuestionType } from '@/api/questions/types';
import {
	QuestionMultipleChoice,
	QuestionMultipleChoiceProps,
} from '@/components/feature/questions/question/QuestionMultipleChoice';
import { QuestionAnswer } from '@/shared/types/questions.types';
import { QuestionSingleChoice, QuestionSingleChoiceProps } from '../question/QuestionSingleChoice';
import { QuestionOpen, QuestionOpenProps } from '../question/QuestionOpen';
import { QuestionAction } from '../question/QuestionAbstract';
import useQuestionGroupScroll from './useQuestionGroupScroll';

interface QuestionGroupAction {
	label: ReactNode;
	disabled?: boolean;
	callback: () => void;
}

export interface QuestionGroupProps {
	questions: Question[];
	currentQuestion: number;
	setCurrentQuestion: (question: number) => void;
	answers: QuestionAnswer;
	actions?: QuestionGroupAction;
	onNextStep?: QuestionGroupAction;
	onChange: (questionId: string, answer: string, type?: string) => void;
	scrollElementRef: MutableRefObject<HTMLDivElement | null>;
	belongsToActivePanel?: boolean; // pass when question group belongs to a panel
	isFinalEnd?: boolean;
	belongsToSlowBrain?: boolean;
}

const QuestionGroup: FunctionComponent<QuestionGroupProps> = ({
	questions,
	currentQuestion,
	setCurrentQuestion,
	answers,
	onNextStep,
	onChange,
	scrollElementRef,
	belongsToActivePanel = true,
	isFinalEnd = true,
	belongsToSlowBrain = false,
}): ReactElement => {
	const questionRefs = useRef<Array<HTMLDivElement | null>>([]);
	useQuestionGroupScroll({ questionRefs, scrollElementRef, currentQuestion, setCurrentQuestion });

	const getQuestionAction = (
		serialNumber: number,
		isRequired: boolean = false
	): QuestionAction | undefined => {
		if (serialNumber !== questions.length || !isFinalEnd) {
			return {
				label: 'Next',
				callback: () => setCurrentQuestion(currentQuestion + 1),
				...(isRequired ? { disabled: !isFinalEnd } : {}),
			};
		}

		return onNextStep;
	};

	const onChoiceQuestionAnswered = (
		questionId: string,
		answer: string,
		type: QuestionType.SINGLE_CHOICE
	) => {
		onChange(questionId, answer, type);

		if (type === QuestionType.SINGLE_CHOICE) {
			if (currentQuestion < questions.length - 1) {
				setCurrentQuestion(currentQuestion + 1);
			}
		}
	};

	const onSingleChoiceQuestionAnswered = (questionId: string, answer: string, type: string) => {
		onChange(questionId, answer, type);
		if (currentQuestion < questions.length - 1) {
			setCurrentQuestion(currentQuestion + 1);
		}
	};

	useEffect(() => {
		if (!belongsToSlowBrain) return;
		if (currentQuestion < questions.length - 1) {
			setCurrentQuestion(currentQuestion + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions.length]);

	const onMultiChoiceQuestionAnswered = onChange;
	const onOpenQuestionAnswered = onChange;

	const getQuestionComponent = (question: Question, index: number): ReactElement => {
		const props = {
			question,
			serialNumber: index + 1,
			action: getQuestionAction(index + 1),
			prevDisabled: currentQuestion === 0,
			nextDisabled: currentQuestion === questions.length - 1,
			allAnswers: answers,
			onButtonNextClick: () => setCurrentQuestion(currentQuestion + 1),
			onButtonPrevClick: () => setCurrentQuestion(currentQuestion - 1),
			onChange: onChoiceQuestionAnswered,
		};

		const singleChoiceQuestionProps = {
			answer: answers[question.id]?.[0],
			onChange: onSingleChoiceQuestionAnswered,
			type: question.type,
			action: getQuestionAction(index + 1, question.questionMetadata[0].required),
		};
		const multiChoiceQuestionProps = {
			answers: answers[question.id] as string[],
			onChange: onMultiChoiceQuestionAnswered,
			type: question.type,
		};
		const openQuestionProps = {
			answer: answers[question.id] as string | null,
			placeholder: 'Type your answer here',
			onChange: onOpenQuestionAnswered,
			onPressEnter: () => {
				if (currentQuestion < questions.length - 1) {
					setCurrentQuestion(currentQuestion + 1);
				}
			},
			showInfo: false,
			isFocused: belongsToActivePanel && currentQuestion === index,
			classes: ['text-input-speech'],
		};

		switch (question.type) {
			case QuestionType.SINGLE_CHOICE: {
				Object.assign(props, singleChoiceQuestionProps);
				break;
			}
			case QuestionType.MULTIPLE_CHOICE: {
				Object.assign(props, multiChoiceQuestionProps);
				break;
			}
			default:
				Object.assign(props, openQuestionProps);
		}

		if (question.type === QuestionType.MULTIPLE_CHOICE) {
			return (
				<QuestionMultipleChoice
					// eslint-disable-next-line no-return-assign
					ref={(element: HTMLDivElement) => (questionRefs.current[index] = element)}
					key={question.id}
					{...(props as QuestionMultipleChoiceProps)}
				/>
			);
		}
		if (question.type === QuestionType.SINGLE_CHOICE) {
			return (
				<QuestionSingleChoice
					// eslint-disable-next-line no-return-assign
					ref={(element: HTMLDivElement) => (questionRefs.current[index] = element)}
					key={question.id}
					{...(props as QuestionSingleChoiceProps)}
				/>
			);
		}
		return (
			<QuestionOpen
				// eslint-disable-next-line no-return-assign
				ref={(element: HTMLDivElement) => (questionRefs.current[index] = element)}
				key={question.id}
				{...(props as QuestionOpenProps)}
			/>
		);
	};

	return (
		<div className="h-full">
			{questions.map((question, index) => (
				<div
					className="md:py-0 flex min-h-full snap-start flex-col justify-center py-20"
					key={question.id}
				>
					{getQuestionComponent(question, index)}
				</div>
			))}
		</div>
	);
};

export default QuestionGroup;
