import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Engine } from 'react-babylonjs';
import { FastBrainTileSelectionLoaderResult } from '@/router/routes/protected/fast-brain';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { ButtonBack, ButtonNext } from '@/components/ui/button';
import BabylonjsLoader from '@/components/feature/babylonjs-loader';
import FastBrainTileScene from '@/views/scenes/FastBrainTileScene';
import {
	useFastBrainSelectTiles,
	useFastBrainTileSelectionData,
	useFastBrainTileSelectionLoader,
	useIsFastBrainTileSelectionDisabled,
} from '@/features/fast-brain/tile-selection/hooks';
import HintsPopupBodyList from '@/features/helpful-hints/components/hints-popup-body';
import { fastBrainHints } from '../../../../../features/fast-brain/hints';
import StepDescriptionDialog from '@/features/slow-brain/tile-selection/components/step-description-dialog';
import { FAST_BRAIN_DIALOG_CONTENT_MAP } from '@/features/fast-brain/const';
import { HINTS_DELAY, HINTS_DELAY_EDIT, isGuidedByClient } from '@/lib/utils';

const FastBrainTileSelection: FunctionComponent = (): ReactElement => {
	const [hintsOpenDefault, setHintsDefaultOpen] = useState<boolean>(false);
	const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(true);
	const [isSceneLoaded, setIsSceneLoaded] = useState<boolean>(false);
	const guidedByClient = isGuidedByClient();

	// Scene loader
	const isSceneLoading = useFastBrainTileSelectionLoader();

	// Extract loader data
	const { tileGroups: tilesInitialData, tileGroupsSelectedTiles: selectedTilesInitialData } =
		useLoaderData() as FastBrainTileSelectionLoaderResult;

	// Get tile selection data: tile groups and selected tiles
	const { tileGroups, tileGroupsSelectedTiles, invalidateSelectedTiles } =
		useFastBrainTileSelectionData({
			tileGroupsInitialData: tilesInitialData,
			tileGroupsSelectedTilesInitialData: selectedTilesInitialData,
		});

	// Extract tile group because fast brain only has one tile group;\
	const tileGroup = useMemo(() => tileGroups[0], [tileGroups]);

	// Perform submit
	const navigate = useNavigate();
	const { mutate: onSubmit, isPending: isTileSelectionPending } = useFastBrainSelectTiles({
		tileGroup,
		onSuccess: () => {
			navigate('../fast-brain/tile-questions/intro');
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			invalidateSelectedTiles();
		},
	});

	const isDisabled = useIsFastBrainTileSelectionDisabled({
		isSceneLoading,
		isTileSelectionPending,
	});

	const controls = (
		<div className="flex flex-row items-center justify-center gap-8">
			<ButtonBack onClick={() => navigate('../fast-brain/tile-selection/intro')} />
			<ButtonNext
				onClick={() => {
					onSubmit();
				}}
				disabled={isDisabled}
			>
				Continue
			</ButtonNext>
		</div>
	);

	const hints: ReactNode = <HintsPopupBodyList data={fastBrainHints.tileSelection?.list ?? []} />;

	useEffect(() => {
		const delayTime = tileGroupsSelectedTiles.length ? HINTS_DELAY_EDIT : HINTS_DELAY;
		if (!isDescriptionOpen && guidedByClient) {
			setTimeout(() => {
				setHintsDefaultOpen(true);
			}, delayTime);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDescriptionOpen]);

	return (
		<div className="flex h-full flex-col bg-neutral-page">
			<Header
				titleText="Fast Brain"
				titleLabel="Tile Selection"
				hints={hints}
				hintsOpenDefault={hintsOpenDefault}
			/>
			<div className="relative flex-1 overflow-hidden">
				{isSceneLoaded && (
					<>
						{isSceneLoading && <BabylonjsLoader />}
						<Engine antialias adaptToDeviceRatio canvasId="babylonjs-canvas">
							<FastBrainTileScene
								tilesGroup={tileGroups}
								selectedTilesGroup={tileGroupsSelectedTiles}
							/>
						</Engine>
					</>
				)}
			</div>
			<Footer withBoxShadow={true} itemsPosition="center" size="lg" controls={controls} />

			<StepDescriptionDialog
				open={isDescriptionOpen}
				onClose={() => {
					setIsSceneLoaded(true);
					setIsDescriptionOpen(false);
				}}
				{...FAST_BRAIN_DIALOG_CONTENT_MAP.tile_selection}
			/>
		</div>
	);
};

export default FastBrainTileSelection;
