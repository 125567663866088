import { QuestionType } from '@/api/questions/types';
import { Answer } from '../types';

export const portfolioRecommendationAnswers: Answer[] = [
	{
		question: {
			id: '4b2fdfc2-1113-40a3-93c9-9fd5719814d1',
			flow: 'recommendation',
			category: null,
			tile: null,
			tag: null,
			type: QuestionType.SINGLE_CHOICE,
			choices: [
				{
					id: '685b2241-a336-4b3d-bdb1-d0a6c2b4f409',
					text: 'YES',
				},
				{
					id: '8cf844d2-00cc-4299-8dd5-12d43651ac19',
					text: 'NO',
				},
			],
			orderNo: 1,
			questionMetadata: [
				{
					label: '',
					text: 'You intend to withdraw principal from this account for a major purchase.',
					subtext: '',
					hint: '',
					imageUrl: '',
					required: false,
				},
			],
		},
		answer: {
			answerText: null,
			answerIds: ['685b2241-a336-4b3d-bdb1-d0a6c2b4f409'],
		},
	},
	{
		question: {
			id: '5f87305d-9df7-495a-8075-976f3384f0b1',
			flow: 'recommendation',
			category: null,
			tile: null,
			tag: null,
			type: QuestionType.SINGLE_CHOICE,
			choices: [
				{
					id: '45296b04-141e-4c19-a767-d29457f0e963',
					text: 'YES',
				},
				{
					id: 'b62d361d-3ec4-4464-881c-05bc4e12d07a',
					text: 'NO',
				},
			],
			orderNo: 2,
			questionMetadata: [
				{
					label: '',
					text: 'You believe markets are efficient and it is impossible to time the market.',
					subtext: '',
					hint: '',
					imageUrl: '',
					required: false,
				},
			],
		},
		answer: {
			answerText: null,
			answerIds: ['b62d361d-3ec4-4464-881c-05bc4e12d07a'],
		},
	},
];
