import { Question, QuestionDependency } from '@/api/questions/types';
import { AuthUser, Flow, GuidingTypes } from '@/api/users/types';
import { QuestionAnswer } from '@/shared/types/questions.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const classNames = (...args: any[]) => args.filter(Boolean).join(' ');

export const HINTS_DELAY = 3500;
export const HINTS_DELAY_EDIT = 1500;
export const CLIENT_ID_KEY = 'clientId';
export const USER_INFO_KEY = 'user';
export const TOKEN_KEY = 'token';

export const getClientId = () => localStorage.getItem(CLIENT_ID_KEY) || '';

export const getUser = () => JSON.parse(localStorage.getItem(USER_INFO_KEY) || '{}') as AuthUser;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY) || '';

export const isCurrentFlowFinished = (flow: Flow) => {
	const user = getUser();
	return user?.clientFlows.find((fl) => fl.flowName === flow)?.status === 'FINISHED';
};

export const isGuidedByClient = () => {
	const user = getUser();
	return user?.dialogueGuidedBy === GuidingTypes.CLIENT;
};

export const renderFormattedText = (text: string) => {
	const modifiedText = text.replace(/^-\s*/, '• ');

	const parts = modifiedText.split(/(\*\*.*?\*\*)/g);
	return parts.map((part, index) => {
		const isBold = part.startsWith('**') && part.endsWith('**');
		const displayText = isBold ? part.slice(2, -2) : part;
		return (
			<span key={index} style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
				{displayText}
			</span>
		);
	});
};

/**
 * Filters questions by keeping all questions until the first required question without an answer.
 * @param {Question[]} questions - The list of questions to filter.
 * @param {QuestionAnswer} answers - The current answers object.
 * @returns {Question[]} The filtered list of questions.
 */
export const filterQuestions = (questions: Question[], answers: QuestionAnswer): Question[] => {
	const cutoffIndex = questions.findIndex(
		(q) => q.questionMetadata.some((qm) => qm.required) && !answers[q.id]?.length
	);

	return cutoffIndex === -1 ? questions : questions.slice(0, cutoffIndex + 1);
};

export const checkDependent = ({
	dependency,
	allAnswers,
}: {
	dependency?: QuestionDependency | undefined;
	allAnswers: QuestionAnswer | undefined;
}) => {
	if (!dependency) return true;

	const { answer_id: answerId, question_id: questionId } = dependency;
	const answer = allAnswers?.[questionId][0];

	return answer === answerId;
};
