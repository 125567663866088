/* eslint-disable indent */
import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AnswerChoicesPayload } from '@/api/answers/types';
import {
	ConsiderationQuestionGroups,
	PortfolioConsiderationQuestion,
	Question,
	RiskLevel,
} from '@/api/questions/types';
import { postAnswers } from '@/api/answers';
import {
	usePortfolioRecommendationQuestionsAndAnswers,
	usePortfolioRecommendationRiskToleranceLevel,
} from '@/query/queries/portfolio-recommendations';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { PortfolioRecommendationsForm } from '@/features/portfolio-recommendations';

const beliefGroup = ConsiderationQuestionGroups.PORTFOLIO_BELIEF_SYSTEM;

const PortfolioRecommendationsQuestionnaire: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();
	const [questionnaire, setQuestionnaire] = useState<Record<string, string[]>>({});
	const [riskToleranceData, setRiskToleranceData] = useState<RiskLevel>();
	const [mappedQuestions, setMappedQuestions] = useState<PortfolioConsiderationQuestion[]>([]);
	const [beliefGroupQuestions, setBeliefQuestions] =
		useState<PortfolioConsiderationQuestion['questions']>();

	const isAnsweredMatched = (choices: Question['choices'], answer: string, match: string) =>
		choices.find((ch) => ch.id === answer)?.text.toLowerCase() === match;

	const answeredQuestion = (questionId: Question['id']) =>
		mappedQuestions.flatMap((mp) => mp.questions).find((q) => q.id === questionId) as Question;

	const onAnswerChange = (questionId: Question['id'], answer: string) => {
		const { orderNo, choices } = answeredQuestion(questionId);

		const [first, ...rest] = beliefGroupQuestions ?? [];

		if (orderNo === 3) {
			setQuestionnaire({
				...questionnaire,
				[first.id]: [answer],
				...rest.reduce(
					(acc, rs: Question) => {
						acc[rs.id] = isAnsweredMatched(choices, answer, 'yes') ? [rs.choices[1].id] : [];
						return acc;
					},
					{} as Record<string, string[]>
				),
			});
			return;
		}

		if ((orderNo === 4 || orderNo === 5) && isAnsweredMatched(choices, answer, 'no')) {
			setQuestionnaire({
				...questionnaire,
				[questionId]: [answer],
				...rest
					.filter((r) => r.orderNo !== orderNo)
					.reduce(
						(acc, rs: Question) => {
							acc[rs.id] = [rs.choices[0].id];
							return acc;
						},
						{} as Record<string, string[]>
					),
			});
			return;
		}

		setQuestionnaire({ ...questionnaire, [questionId]: [answer] });
	};

	const [
		{ data: questions, isFetched: isQuestionsFetched },
		{ data: answers, isFetched: isAnswersFetched },
	] = usePortfolioRecommendationQuestionsAndAnswers();

	const { data: riskTolerance, isFetched: isRiskLevelFetched } =
		usePortfolioRecommendationRiskToleranceLevel();

	useEffect(() => {
		if (!questions || !isQuestionsFetched) return;
		if (!answers || !isAnswersFetched) return;

		const mapQuestions = questions.map((qg) => ({
			...qg,
			questions: qg.questions.map((q) => ({
				...q,
				disabledYes: [4, 5].includes(q.orderNo),
				disabledNo: [4, 5].includes(q.orderNo),
			})),
		}));

		setBeliefQuestions(mapQuestions?.find((qg) => qg.groupId === beliefGroup)?.questions);
		setMappedQuestions(mapQuestions);

		const questionnaire = mapQuestions
			.flatMap((q) => q.questions)
			.reduce(
				(prev, curr) => ({
					...prev,
					[curr.id]: [...(answers.find((a) => a.question.id === curr.id)?.answer?.answerIds || [])],
				}),
				{}
			);

		setQuestionnaire(questionnaire);
		setRiskToleranceData(riskTolerance);
	}, [questions, isQuestionsFetched, answers, isAnswersFetched, riskTolerance, isRiskLevelFetched]);

	useEffect(() => {
		let questionGroupQuestions = beliefGroupQuestions;
		const [first, ...rest] = beliefGroupQuestions ?? [];
		const { id, choices } = (first ?? {}) as Question;

		if (!id) return;

		const answer = questionnaire[id][0];

		rest.forEach((r: Question) => {
			questionGroupQuestions = questionGroupQuestions?.map((q) =>
				r.id === q.id
					? {
							...q,
							disabledYes: isAnsweredMatched(choices, answer, 'yes'),
							disabledNo: isAnsweredMatched(choices, answer, 'yes'),
						}
					: { ...q }
			);
		});

		setMappedQuestions(
			mappedQuestions.map((mq) =>
				mq.groupId === beliefGroup ? { ...mq, questions: questionGroupQuestions ?? [] } : { ...mq }
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaire]);

	const { mutate: submitAnswers, isPending: isSubmitting } = useMutation({
		mutationFn: () => {
			const requestPayload: AnswerChoicesPayload[] = Object.entries(questionnaire).map(
				([key, value]) => ({ questionId: key, choices: value })
			);
			return postAnswers(requestPayload);
		},
		onSuccess: () => navigate('../portfolio-considerations/client-report'),
	});

	const isDisabled = useMemo(
		() => isSubmitting || !Object.entries(questionnaire).every(([, answer]) => !!answer.length),
		[isSubmitting, questionnaire]
	);
	const isContentReady = useMemo(
		() => isQuestionsFetched && isAnswersFetched,
		[isQuestionsFetched, isAnswersFetched]
	);

	if (!isContentReady) return <></>;

	return (
		<div className="flex min-h-[100%] flex-col bg-neutral-page">
			<Header titleText="Portfolio Considerations" />
			<main className="container flex flex-1 items-center justify-center p-16">
				<div className="m-auto flex w-full max-w-[872px] flex-col gap-12">
					<div className="w-full rounded-lg border-2 border-blue-primary bg-blue-50 p-6 text-center">
						<h1 className="text-heading-24">
							Your Risk Profile:{' '}
							{riskToleranceData && (
								<span className="text-blue-dark">{riskToleranceData?.riskToleranceLevel}</span>
							)}
						</h1>
					</div>
					<div className="flex flex-col gap-8">
						<h2 className="text-heading-24 text-blue-dark">Consideration Questions</h2>
						{!!questions?.length && (
							<PortfolioRecommendationsForm
								questionsGroup={mappedQuestions}
								answers={questionnaire}
								onAnswerChange={onAnswerChange}
								onSubmit={() => {
									submitAnswers();
								}}
								disabled={isDisabled}
							/>
						)}
					</div>
				</div>
			</main>
			<Footer withBoxShadow={false} itemsPosition="start" size="md" />
		</div>
	);
};

export default PortfolioRecommendationsQuestionnaire;
