import { Question, QuestionType } from '../types';

export const slowBrainQuestions: Question[] = [
	{
		id: '550e8400-e29b-41d4-a716-446655440000',
		type: QuestionType.OPEN,
		flow: 'slow_brain',
		category: '',
		tag: 'Career',
		tile: '',
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'On the tiles you’ve chosen, can you identify a specific instance in your life where you can trace where this “rule of thumb” came from?',
				subtext: '',
				hint: '',
				imageUrl: null,
				required: false,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-446655440001',
		type: QuestionType.OPEN,
		flow: 'slow_brain',
		category: '',
		tag: 'Career',
		tile: '',
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'Can you identify specific decisions you’ve made where you’ve used one of these “rules of thumb” as your guiding principle?',
				subtext: '',
				hint: '',
				imageUrl: null,
				required: false,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-446655440000',
		type: QuestionType.OPEN,
		flow: 'slow_brain',
		category: '',
		tag: 'Career',
		tile: '',
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s?',
				subtext: '',
				hint: '',
				imageUrl: null,
				required: false,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-446655440001',
		type: QuestionType.OPEN,
		flow: 'slow_brain',
		category: '',
		tag: 'Values',
		tile: '',
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
				subtext: '',
				hint: '',
				imageUrl: null,
				required: false,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-446655440000',
		type: QuestionType.OPEN,
		flow: 'slow_brain',
		category: '',
		tag: 'Passions',
		tile: '',
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the?',
				subtext: '',
				hint: '',
				imageUrl: null,
				required: false,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-446655440001',
		type: QuestionType.OPEN,
		flow: 'slow_brain',
		category: '',
		tag: 'Passions',
		tile: '',
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy?',
				subtext: '',
				hint: '',
				imageUrl: null,
				required: false,
			},
		],
	},
];
