import { TileTag, CategoryName, FlowTag, TileName } from '../tile-groups/types';

export enum QuestionType {
	SINGLE_CHOICE = 'SINGLE_CHOICE',
	MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
	OPEN = 'OPEN',
}

export enum ConsiderationQuestionGroups {
	LIQUIDITY_NEEDS = 'liquidity_needs',
	PORTFOLIO_BELIEF_SYSTEM = 'portfolio_belief_system',
	RETIREMENT_HORIZON = 'retirement_horizon',
	PORTFOLIO_THEMES = 'portfolio_themes',
}

export type ConsiderationGroupType =
	| ConsiderationQuestionGroups.LIQUIDITY_NEEDS
	| ConsiderationQuestionGroups.PORTFOLIO_BELIEF_SYSTEM
	| ConsiderationQuestionGroups.PORTFOLIO_THEMES
	| ConsiderationQuestionGroups.RETIREMENT_HORIZON;

export interface Choice {
	id: string;
	text: string;
}

export interface QuestionDependency {
	answer_id: string;
	question_id: string;
}

export interface QuestionMetadata {
	label: string;
	text: string;
	subtext: string;
	hint: string;
	imageUrl: string | null;
	dependency?: QuestionDependency;
	required: boolean;
}

export interface Question {
	id: string;
	type: QuestionType;
	flow: FlowTag;
	category: CategoryName | null;
	tag: TileTag | null;
	tile: TileName | null;
	choices: Choice[];
	orderNo: number;
	questionMetadata: QuestionMetadata[];
}

export interface PortfolioConsiderationQuestion {
	questions: (Question & { disabledNo?: boolean; disabledYes?: boolean })[];
	groupId: ConsiderationGroupType;
	title: string;
}

export interface Section {
	header: string;
	text: string[];
}

export interface Output {
	sections: Section[];
}

export interface RiskLevel {
	riskToleranceLevel: string;
}
