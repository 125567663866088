import { forwardRef, ReactElement, ReactNode } from 'react';
import { Question } from '@/api/questions/types';
import { ArrowRightRegular } from '@/assets/icons/ArrowRightRegular';
import { ButtonNext } from '@/components/ui/button';
import ButtonPrevNext from '@/components/ui/button/button-prev-next';
import { LightbulbOnLight } from '@/assets/icons/LightbulbOnLight';
import { QuestionAnswer } from '@/shared/types/questions.types';
import { checkDependent } from '@/lib/utils';

export interface QuestionAction {
	label?: ReactNode;
	disabled?: boolean;
	callback?: () => void;
}

export interface QuestionAbstractProps {
	question: Question;
	serialNumber: number;
	prevDisabled?: boolean;
	nextDisabled?: boolean;
	action?: QuestionAction;
	allAnswers?: QuestionAnswer;
	onButtonNextClick?: () => void;
	onButtonPrevClick?: () => void;
	children: ReactNode;
}

const QuestionAbstract = forwardRef<HTMLDivElement, QuestionAbstractProps>(
	(
		{
			question,
			serialNumber,
			prevDisabled,
			nextDisabled,
			action,
			allAnswers,
			onButtonNextClick,
			onButtonPrevClick,
			children,
		},
		ref
	): ReactElement => (
		<div ref={ref} className="flex h-full items-center justify-center">
			{/* Question Layout */}
			<div className="flex w-full max-w-[872px] flex-row gap-4">
				{/* Question Serial Number */}
				<div className="flex h-[30px] flex-row items-center justify-center gap-1 self-start text-body-18-md text-blue-primary">
					<span>{serialNumber}</span>
					<ArrowRightRegular width={18} height={18} />
				</div>
				{/* End Question Serial Number */}
				{/* Question */}
				<div className="flex w-full flex-col gap-8">
					{question.questionMetadata.map(
						({ dependency, text, hint, imageUrl, required }, index) =>
							checkDependent({ allAnswers, dependency }) && (
								<div className="flex flex-col gap-4" key={index}>
									<div className="flex flex-col gap-4">
										<div className="flex flex-col gap-8">
											<p className="text-heading-24 text-blue-dark">
												{text} {required && '*'}
											</p>
											{imageUrl && (
												<img className="self-start" src={imageUrl} alt="Question Illustration" />
											)}
										</div>
										{hint && (
											<p className="flex flex-row gap-2 text-neutral-700">
												<span className="h-[22px] w-[22px]">
													<LightbulbOnLight />
												</span>
												<span className="text-body-16">{hint}</span>
											</p>
										)}
									</div>
								</div>
							)
					)}

					<form className="flex flex-col gap-2">{children}</form>
					{/* Controls */}
					<div className="flex flex-row items-center justify-between">
						<ButtonNext
							showIcon={false}
							onClick={() => action?.callback?.()}
							disabled={action?.disabled}
						>
							{action?.label}
						</ButtonNext>
						<ButtonPrevNext
							onPrevClick={() => onButtonPrevClick?.()}
							onNextClick={() => onButtonNextClick?.()}
							prevDisabled={prevDisabled}
							nextDisabled={nextDisabled}
						/>
					</div>
					{/* End Controls */}
				</div>
				{/* End Question */}
			</div>
			{/* End Question Layout */}
		</div>
	)
);

// Set displayName for debugging and linting
QuestionAbstract.displayName = 'Question';

export default QuestionAbstract;
