import { httpClient } from '@/api/http';
import { Output, PortfolioConsiderationQuestion, RiskLevel } from '../types';
import { portfolioRecommendationQuestions } from './mocks';

export const getPortfolioRecommendationQuestions = async (): Promise<
	PortfolioConsiderationQuestion[]
> => {
	const { data } = await httpClient.get<PortfolioConsiderationQuestion[]>(
		'/flows/recommendation/questions'
	);
	return data;
};

export const getNextBestActionsOutput = async (): Promise<Output> => {
	const { data } = await httpClient.get<Output>('/flow/next_best_actions/output');
	return data;
};

export const getRiskToleranceLevel = async (): Promise<RiskLevel> => {
	const { data } = await httpClient.get<RiskLevel>('/flows/risk_assessment/risk_tolerance_level');
	return data;
};

export const mockGetPortfolioRecommendationQuestions = async (): Promise<
	PortfolioConsiderationQuestion[]
> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(portfolioRecommendationQuestions), 500);
	});
