import RadioInput from '../radio-input';
import './yes-no-answer.scss';

interface YesNoAnswerProps {
	id?: string;
	name?: string;
	value?: string;
	yesValue?: string;
	noValue?: string;
	disabledYes?: boolean;
	disabledNo?: boolean;
	onChange?: (e: string) => void;
	withIcon?: boolean;
}

const YesNoAnswer: React.FC<YesNoAnswerProps> = ({
	id = 'answer',
	name = 'answer',
	value,
	yesValue,
	noValue,
	disabledYes = false,
	disabledNo = false,
	onChange,
	withIcon = false,
}) => (
	<div className="yes-no-answer">
		<RadioInput
			id={`${id}-yes`}
			name={name}
			label="Yes"
			value={yesValue}
			withIcon={withIcon}
			onChange={onChange}
			checked={value === yesValue}
			disabled={disabledYes}
		/>
		<RadioInput
			id={`${id}-no`}
			name={name}
			label="No"
			value={noValue}
			withIcon={withIcon}
			onChange={onChange}
			checked={value === noValue}
			disabled={disabledNo}
		/>
	</div>
);

export default YesNoAnswer;
