import { QuestionType } from '@/api/questions/types';
import { Answer } from '../types';

const riskAssessmentGeneralInvestment: Answer[] = [
	{
		question: {
			id: '550e8400-e29b-41d4-a716-446655440000',
			type: QuestionType.SINGLE_CHOICE,
			flow: 'risk_assessment',
			category: 'General Investment Questions',
			tile: 'General Investment Questions',
			tag: 'general_investment',
			orderNo: 1,
			choices: [
				{
					id: '550e8400-e29b-41d4-a716-446655440001',
					text: 'Current income',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440002',
					text: 'Retirement Savings',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440003',
					text: 'Growth and Income',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440004',
					text: 'Growth',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440005',
					text: 'Speculate',
				},
			],
			questionMetadata: [
				{
					label: '',
					text: 'What is your primary purpose for investing?',
					subtext: '',
					hint: '',
					imageUrl: null,
					required: false,
				},
			],
		},
		answer: {
			answerText: null,
			answerIds: ['550e8400-e29b-41d4-a716-446655440001'],
		},
	},
	{
		question: {
			id: '550e8400-e29b-41d4-a716-44665544001',
			type: QuestionType.MULTIPLE_CHOICE,
			flow: 'risk_assessment',
			category: 'General Investment Questions',
			tile: 'General Investment',
			tag: 'general_investment',
			orderNo: 2,
			choices: [
				{
					id: '550e8400-e29b-41d4-a716-446655440001',
					text: 'I am not concerned with a loss, I believe I will make it back',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440002',
					text: 'Provided the loss is less than 20%, I believe I will make it back',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440003',
					text: 'Provided the loss is less than 10%, I believe I will make it back',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440004',
					text: 'Provided the loss is less than 5%, I believe I will make it back',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440005',
					text: 'I am not comfortable with losses and prefer predictable returns',
				},
			],
			questionMetadata: [
				{
					label: '',
					text: 'Which of these statements best describes your attitude about the next five years performance of your investments?',
					subtext: '',
					hint: '',
					imageUrl: null,
					required: false,
				},
			],
		},
		answer: {
			answerText: null,
			answerIds: ['550e8400-e29b-41d4-a716-446655440002', '550e8400-e29b-41d4-a716-446655440003'],
		},
	},
];

const riskAssessmentInvestmentBehavior: Answer[] = [];

const riskAssessmentEmotionalRiskTolerance: Answer[] = [];

export const riskAssessmentAnswers = [
	...riskAssessmentGeneralInvestment,
	...riskAssessmentInvestmentBehavior,
	...riskAssessmentEmotionalRiskTolerance,
];
