/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { Section } from '@/api/questions/types';
import Card from './Card';
import '@/scss/_output.scss';

interface StackingCardProps {
	sections: Section[];
	topAlign?: boolean;
}

const StackingCards: FunctionComponent<StackingCardProps> = ({
	sections,
	topAlign,
}): ReactElement => {
	const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
	const [heightDifferences, setHeightDifferences] = useState<Record<number, number>>({});
	const [intersectingCards, setIntersectingCards] = useState(new Set<number>());
	const [isContentLoaded, setIsContentLoaded] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);
	const [isFullyVisible, setIsFullyVisible] = useState(false);

	useEffect(() => {
		const updateHeights = () => {
			const heights = cardRefs.current.map((card) => card?.getBoundingClientRect().height || 0);
			const newHeightDifferences: Record<number, number> = {};

			heights.forEach((currentHeight, i) => {
				const nextHeight = heights[i + 1] || 0;
				newHeightDifferences[i + 1] = Math.max(0, currentHeight - nextHeight);
			});

			setHeightDifferences(newHeightDifferences);
			setIsContentLoaded(true);
		};

		const timeout = setTimeout(updateHeights, 100);
		return () => clearTimeout(timeout);
	}, [sections]);

	useEffect(() => {
		if (!isContentLoaded) return;

		let scrollTimeout: NodeJS.Timeout | null = null;

		const checkVisibility = () => {
			const firstCard = cardRefs.current[0];
			if (!firstCard) return;

			const rect = firstCard.getBoundingClientRect();
			setIsFullyVisible(rect.bottom <= window.innerHeight);
		};

		const checkIntersections = () => {
			const newIntersecting = new Set<number>();

			cardRefs.current.forEach((cardA, i) => {
				const cardB = cardRefs.current[i + 1];
				if (!cardA || !cardB) return;

				const rectA = cardA.getBoundingClientRect();
				const rectB = cardB.getBoundingClientRect();

				if (
					rectA.left < rectB.right &&
					rectA.right > rectB.left &&
					rectA.top < rectB.bottom &&
					rectA.bottom > rectB.top
				) {
					newIntersecting.add(i);
					newIntersecting.add(i + 1);
				}
			});

			setIntersectingCards(newIntersecting);
			setIsScrolling(false);
		};

		const handleScroll = () => {
			if (!isScrolling) setIsScrolling(true);
			if (scrollTimeout) clearTimeout(scrollTimeout);
			scrollTimeout = setTimeout(checkIntersections, 100);
		};

		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', checkIntersections);
		checkIntersections();
		checkVisibility();

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', checkIntersections);
			if (scrollTimeout) clearTimeout(scrollTimeout);
		};
	}, [isContentLoaded, isScrolling]);

	return (
		<div className="cards-container">
			<ul id="cards">
				{sections.map((section, i) => (
					<Card
						key={section.header}
						ref={(el) => (cardRefs.current[i] = el)}
						item={section}
						ind={i}
						topAlign={topAlign}
						isIntersecting={intersectingCards.has(i)}
						heightDiff={heightDifferences[i] || 0}
						visibility={{ isFullyVisible }}
					/>
				))}
				<Card
					ref={(el) => (cardRefs.current[sections.length] = el)}
					ind={sections.length}
					item={null}
					topAlign={topAlign}
					isIntersecting={intersectingCards.has(sections.length)}
					heightDiff={heightDifferences[sections.length] || 0}
				/>
			</ul>
		</div>
	);
};

export default StackingCards;
