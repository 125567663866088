import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const FastBrainTileSelectionIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Selection"
		heading="Fast Brain"
		link={{ to: '../fast-brain/tile-selection', text: 'Start Tile Selection' }}
	>
		You will now see a series of tiles representing common &quot;rules of thumb&quot;—mental
		shortcuts we use daily, many of which you may recognize. While we often believe our decisions
		are driven by logic or the pursuit of satisfaction, they are often guided by these shortcuts.
		Shaped by past experiences, these &quot;rules of thumb&quot; help simplify our decision-making
		process.
	</IntroLayout>
);

export default FastBrainTileSelectionIntro;
