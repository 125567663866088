import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
// import { useQuery } from '@tanstack/react-query';
// import { useFastBrainTileGroupsSelectedTilesOptions } from '@/query/queries/fast-brain';
import {
	usePortfolioRecommendationQuestionsAndAnswers,
	usePortfolioRecommendationRiskToleranceLevel,
	usePortfolioRecommendations,
} from '@/query/queries/portfolio-recommendations';
// import { useSlowBrainPickedTilesOptions } from '@/query/queries/slow-brain';
import Footer from '@/components/ui/footer';
import { UserBadge } from '@/components/feature/badges';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import LinkButton from '@/components/ui/link-button';
// import SlowBrainSelectedTiles from '@/components/feature/slow-brain-selected-tiles/SlowBrainSelectedTiles';
// import { FastBrainTile } from '@/components/ui/tile';
import {
	ClientReportAnswers,
	PortfolioRecommendations,
	PortfolioRecommendationsConsiderations,
} from '@/features/portfolio-recommendations';
import PageLayout from '@/views/layouts/page-layout';
import { Print } from '@/assets/icons/Print';
import { RiskLevel } from '@/api/questions/types';

const ClientReport: FunctionComponent = (): ReactElement => {
	const [riskToleranceData, setRiskToleranceData] = useState<RiskLevel>();

	// const { data: fastBrainTiles } = useQuery({
	// 	...useFastBrainTileGroupsSelectedTilesOptions,
	// 	select: (data) => data[0].tiles,
	// });

	// const { data: slowBrainTiles } = useQuery({
	// 	...useSlowBrainPickedTilesOptions,
	// });

	const [
		{ data: questions, isFetched: isQuestionsFetched },
		{ data: answers, isFetched: isAnswersFetched },
	] = usePortfolioRecommendationQuestionsAndAnswers();

	const { data: riskTolerance, isFetched: isRiskLevelFetched } =
		usePortfolioRecommendationRiskToleranceLevel();

	const { data: portfolios } = usePortfolioRecommendations();

	const isClientReportReady = useMemo(
		() => isQuestionsFetched && isAnswersFetched && isRiskLevelFetched,
		[isQuestionsFetched, isAnswersFetched, isRiskLevelFetched]
	);

	useEffect(() => {
		setRiskToleranceData(riskTolerance);
	}, [riskTolerance, isRiskLevelFetched]);

	const clientReportAnswers: ClientReportAnswers[] = useMemo(() => {
		if (!isQuestionsFetched || !isAnswersFetched) return [];
		if (!questions?.length || !answers?.length) return [];

		return questions
			.flatMap((q) => q.questions)
			.map((question) => {
				const { id: questionId, choices, orderNo } = question;
				const { text: questionText } = question.questionMetadata[0];

				const [answeredChoice]: (string | undefined)[] =
					answers.find((a) => a.question.id === questionId)?.answer?.answerIds || [];
				const answer = choices.find((c) => c.id === answeredChoice)?.text || '';

				return {
					questionId,
					questionText,
					answer,
					orderNo,
				};
			});
	}, [questions, answers, isQuestionsFetched, isAnswersFetched]);

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			headerControls={
				<ButtonPrimary className="print:hidden" icon={<Print />} onClick={() => window.print()}>
					Save as PDF
				</ButtonPrimary>
			}
		>
			<div className="m-auto p-12">
				<div className="m-auto flex max-w-[872px] flex-col gap-12">
					<div className="flex flex-col gap-6 print:flex-row print:items-center print:justify-start">
						<UserBadge />
						<h1 className="text-results-title">Client Report</h1>
					</div>

					<div className="w-full rounded-lg border-2 border-blue-primary bg-blue-50 p-6 text-center">
						<h1 className="text-heading-24">
							Your Risk Profile:{' '}
							<span className="text-blue-dark">{riskToleranceData?.riskToleranceLevel}</span>
						</h1>
					</div>

					{/* Fast Brain */}
					{/* {!!fastBrainTiles?.length && (
						<div className="flex flex-col gap-4">
							<h3 className="text-heading-24 text-body opacity-70">Fast Brain Beliefs</h3>
							<div className="flex flex-row flex-wrap gap-4">
								{fastBrainTiles?.map(({ text }, index) => (
									<FastBrainTile key={index} text={text} />
								))}
							</div>
						</div>
					)} */}

					{/* Slow Brain */}
					{/* {!!slowBrainTiles?.length && (
						<div className="flex flex-col gap-4">
							<h3 className="text-heading-24 text-body opacity-70">Top 5 Tiles</h3>
							<div className="flex flex-row flex-wrap gap-4">
								<SlowBrainSelectedTiles
									tiles={
										slowBrainTiles?.map((tile) => ({
											tile,
											disabled: false,
											isCompleted: false,
											isSelected: false,
										})) || []
									}
									classes={['opacity-100', 'cursor-default']}
								/>
							</div>
						</div>
					)} */}

					<PortfolioRecommendationsConsiderations
						answers={clientReportAnswers}
						classes={[
							'print:break-inside-avoid',
							'print:bg-inherit',
							'print:p-0',
							'print:shadow-none',
						]}
					/>

					<PortfolioRecommendations
						portfolios={portfolios || []}
						classes={[
							'print:break-inside-avoid',
							'print:bg-inherit',
							'print:p-0',
							'print:shadow-none',
						]}
					/>

					{isClientReportReady && (
						<div className="inline self-end print:hidden">
							<LinkButton to="../portfolio-considerations/next-best-actions">Continue</LinkButton>
						</div>
					)}
				</div>
			</div>
		</PageLayout>
	);
};

export default ClientReport;
