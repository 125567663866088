import { FastBrainDialogContent, FastBrainSelectionMap } from './types';

export const FAST_BRAIN_DIALOG_CONTENT_MAP: Record<FastBrainSelectionMap, FastBrainDialogContent> =
	{
		tile_selection: {
			serialNumber: 0,
			title: 'Fast Brain Tile Selection Instructions',
			subtitles: [],
			info: [
				'1. Take a moment to read through the tiles and choose as many or as few that reflect your beliefs or your typical decision-making patterns.',
				'2. Choose as many or as few as you’d like or simply select the ones that feel most relevant to you.',
				'3. At least one selection is required.',
			],
			actionCopy: 'I’m Ready',
		},
		tile_questions: {
			serialNumber: 0,
			title: 'Tile Reflection Instructions',
			subtitles: [],
			info: [
				'You will now be provided with the tiles you selected and you will be asked two separate questions which will allow you to elaborate on why you selected those tiles.',
			],
			actionCopy: 'I’m Ready',
		},
	};
