import { FastBrainHint } from '@/shared/types/hint.types';

export const fastBrainHints: FastBrainHint = {
	tileSelection: {
		list: [
			{
				title: '',
				data: [
					`Choose at least one tile, but don't feel pressured to select all of them.`,
					`There is no right or wrong number of tiles to select.`,
					`Your choices should be reflective of the information you have.`,
					`Your decisions now will guide future actions.`,
				],
			},
		],
	},
	tileQuestions: {
		list: [
			{
				title: 'Question 1 Hints:',
				description: 'To help with a specific response go through these steps',
				exampleTitle: 'Example:',
				data: [
					`"Less is more" reminds me of when I used to work all the time to earn as much money as I could and although I earned more money I wasn’t as happy as when I worked less and had more free time to spend with the people I love.`,
					`Last year my 62 year old father passed away. He always saved his money and never got to enjoy it. Ever since then I think "you only live once" and I want to enjoy my money while I’m alive.`,
				],
				additionalData: [
					'Narrow down to one or two major events that stick out from your past. It could be something from your parents, school or an encounter with your friends.',
					'Reflect on the lasting impression of that event and what that imprinted on you.',
					'Look at the tiles and consider whether this rule of thumb was driven by that event.',
				],
			},
			{
				title: 'Question 2 Hints:',
				description: 'To help with a specific response go through these steps',
				exampleTitle: 'Example:',
				data: [
					`In the last year, I bought a cruise to Alaska because although it wasn’t in my budget I thought "you only live once" and it was a once in a lifetime opportunity.`,
					`When the stock market dipped down this year, I wanted to put a pause on my investments until things started going back up again, but I remembered to "stay the course" as it’s time in the market and not timing the market that leads to the most financial gains over time.`,
				],
				additionalData: [
					'Narrow down to one or two major decisions you have made in the past year',
					'Reflect on why you decided the way you did',
					'Look at the tiles and consider whether any of these rules of thumb influenced that decision',
				],
			},
		],
	},
};
