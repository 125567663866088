/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-param-reassign */
import { ReactElement, useEffect, useRef, useState, forwardRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import Markdown from 'react-markdown';
import { Section } from '@/api/questions/types';

interface ItemProps {
	item: Section | null;
	ind: number;
	topAlign?: boolean;
	isIntersecting: boolean;
	heightDiff: number;
	visibility?: {
		isFullyVisible: boolean;
	};
}

const Card = forwardRef<HTMLDivElement, ItemProps>(
	({ item, ind, isIntersecting, heightDiff, visibility }, ref): ReactElement => {
		const innerRef = useRef<HTMLLIElement | null>(null);
		const [elHeight, setElHeight] = useState(0);
		const { scrollY } = useScroll({
			target: innerRef,
			offset: ['start start', 'end end'],
		});

		useEffect(() => {
			if (!innerRef.current) return;
			const cardBody = innerRef.current.querySelector<HTMLDivElement>('.card-body');
			const height = cardBody ? cardBody.offsetHeight + 32 : 0;
			setElHeight(height);
		}, [innerRef]);

		const initialScrollHeight = 630;
		const gapsHeight = 128;

		const start = initialScrollHeight + ind * elHeight - gapsHeight;
		const end = initialScrollHeight + (ind + 1) * elHeight - gapsHeight;

		const scale = useTransform(scrollY, [start, end], [1, 0.9]);

		return (
			<motion.li
				ref={(node) => (innerRef.current = node)}
				style={{
					scale,
					...{ '--index': ind + 1 },
					...(item ? {} : { visibility: 'hidden' }),
					...(visibility?.isFullyVisible ? { top: '0' } : { top: `-80px` }),
				}}
				className="card"
				id={`card-${ind + 1}`}
			>
				<div className="padding-top-mask w-full"></div>
				<div
					className={'card-body'}
					ref={(node) => {
						if (typeof ref === 'function') ref(node);
						else if (ref) (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
					}}
					style={{ position: 'relative' }}
				>
					<h2 className="text-blue-dark">{item?.header}</h2>
					{item?.text?.map((sct) => (
						<div className="text-body-16-md text-body" key={sct}>
							<Markdown>{sct.replace(/^-\s*/, '• ')}</Markdown>
						</div>
					))}
				</div>
				<div
					className="padding-bottom-mask w-full"
					style={{
						background: isIntersecting ? '#f7f9fc' : 'transparent',
						height: heightDiff ? heightDiff + 128 : 0,
					}}
				></div>
			</motion.li>
		);
	}
);

Card.displayName = 'Card';

export default Card;
