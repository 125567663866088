import { RiInformationLine } from '@remixicon/react';
import { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import HintsPopupHeader from './components/hints-popup-header';

interface PopupProps {
	triggerLabel?: string;
	defaultOpen?: boolean;
	children: ReactNode;
}

const HintsPopup: FunctionComponent<PopupProps> = ({
	triggerLabel = 'Helpful Hints',
	defaultOpen = true,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
	const [isFadingIn, setIsFadingIn] = useState<boolean>(false);
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	useEffect(() => {
		if (isOpen) setIsFadingIn(true);
		else setIsFadingIn(false);
	}, [isOpen]);

	const togglePopup = () => {
		setIsOpen((prev) => !prev);
	};

	useEffect(() => {
		setIsOpen(defaultOpen);
	}, [defaultOpen]);

	const getPopupPosition = () => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect();
			return {
				top: rect.bottom + window.scrollY + 32, // Position it below the button + 32px gap
				left: rect.left + rect.width / 2, // Center horizontally on the button
			};
		}
		return { top: 0, left: 0 };
	};

	const popupPosition = getPopupPosition();

	return (
		<div className="relative">
			<button
				ref={buttonRef}
				onClick={togglePopup}
				className="inline-flex w-max items-center text-sm text-white hover:text-white"
			>
				<RiInformationLine size={16} color="#ffffff" className="mr-2" />
				<span>{triggerLabel}</span>
			</button>

			{isOpen && (
				<div
					className={`fixed z-10 max-h-[462px] w-[270px] rounded-2xl bg-white pb-7 pl-4 pt-5 shadow-lg transition-opacity duration-500 ${isFadingIn ? 'opacity-100' : 'opacity-0'}`}
					style={{
						top: `${popupPosition.top}px`,
						left: `${popupPosition.left}px`,
						transform: 'translate3d(-50%, 0, 0)', // Center the popup horizontally
					}}
				>
					<HintsPopupHeader close={togglePopup} />
					{children}
				</div>
			)}
		</div>
	);
};

export default HintsPopup;
