import { QuestionAnswer } from '@/shared/types/questions.types';

export interface ServerResponse {
	[key: string]: string;
}

export type SlowBrainStepAnswers = {
	[key: string]: QuestionAnswer;
};

export type SlowBrainCurrentQuestions = {
	[key: string]: number;
};

export type SlowBrainTileSelectionStep =
	| 'personal_outcomes'
	| 'money_outcomes'
	| 'plan_vulnerabilities'
	| 'top_5_selection';

export type SlowBrainTileSelectionCategories = 'Personal' | 'Money' | 'Vulnerabilities';

export const SLOW_BRAIN_TILE_SELECTION_COLOR_CODE: Record<
	SlowBrainTileSelectionCategories,
	string
> = {
	Personal: 'blue',
	Money: 'green',
	Vulnerabilities: 'red',
};

export type SlowBrainTileSelectionApiTags =
	| 'Personal Outcomes'
	| 'Money Outcomes'
	| 'Plan Vulnerabilities'
	| 'Top 5 Selection';

export const SLOW_BRAIN_TILE_SELECTION_MAP: Record<
	SlowBrainTileSelectionStep,
	SlowBrainTileSelectionApiTags
> = {
	personal_outcomes: 'Personal Outcomes',
	money_outcomes: 'Money Outcomes',
	plan_vulnerabilities: 'Plan Vulnerabilities',
	top_5_selection: 'Top 5 Selection',
};

export type StepDescriptionDialogContent = {
	serialNumber: number;
	title: string;
	subtitles: string[];
	info: string[];
	actionCopy: string;
};
