/* eslint-disable jsx-a11y/media-has-caption */
import { Fragment, FunctionComponent, useState } from 'react';
import { motion as m } from 'framer-motion';
import { getStartingFlowEntryPoint } from '@/router/utils/flows';
import { PlayLargeFill } from '@/assets/icons/PlayLargeFill';
import VideoPlaceholderImage from '@/assets/video-placeholder-image.png';
import Video from '@/assets/intro-video.mp4';
import Footer from '@/components/ui/footer';
import LinkButton from '@/components/ui/link-button';
import Modal from '@/components/ui/modal/Modal';
import PageLayout from '@/views/layouts/page-layout';
import './home.scss';

const Home: FunctionComponent = () => {
	const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);

	const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };
	const itemVariants = {
		hidden: { opacity: 0, y: 10 },
		visible,
	};

	const startingFlowEntryPoint = getStartingFlowEntryPoint();

	return (
		<Fragment>
			<PageLayout
				theme="light"
				footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			>
				<div className="home-container">
					<m.div
						initial="hidden"
						animate="visible"
						exit={{ opacity: 0, transition: { duration: 1 } }}
						variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
						className="home-content"
					>
						<m.div variants={itemVariants} className="home-title-container">
							<span className="home-title-container__label">Welcome to</span>
							<h1 className="text-intro-script text-body">
								Your Life <span className="font-normal">Plan</span>
							</h1>
						</m.div>
						<m.h2
							variants={itemVariants}
							className="font-caveat text-intro-title font-bold text-blue-primary"
						>
							The life you want to live
						</m.h2>
						<m.p variants={itemVariants} className="max-w-[422px] text-body-18 text-neutral-800">
							We’re excited to help you create a personalized plan that aligns with your values and
							goals. To get started, please watch the introduction video for an overview of the
							process, then click <span className="font-bold">Let’s Get Started</span> to begin your
							journey.
						</m.p>
						<m.div variants={itemVariants}>
							{startingFlowEntryPoint && (
								<LinkButton to="profile-type">Let’s get Started</LinkButton>
							)}
						</m.div>
					</m.div>
					<m.div
						initial="hidden"
						animate="visible"
						exit={{ opacity: 0, transition: { duration: 1 } }}
						variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
						className="home-video-wrapper"
					>
						<m.div variants={itemVariants} className="home-video-container">
							<img src={VideoPlaceholderImage} alt="Laptop" />
							<button className="home-video-play-button" onClick={() => setIsVideoModalOpen(true)}>
								<PlayLargeFill width={64} height={64} /> Play Video
							</button>
						</m.div>
					</m.div>
				</div>
			</PageLayout>
			<Modal
				open={isVideoModalOpen}
				onClose={() => setIsVideoModalOpen(false)}
				keyName="home-video-modal"
			>
				<div className="home-video-content">
					<video controls autoPlay className="home-video">
						<source src={Video} />
					</video>
				</div>
			</Modal>
		</Fragment>
	);
};

export default Home;
